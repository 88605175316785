.fc-event-container .fc-event,
.fc-event-container .fc-event-dot {
  background-color: #39374e !important;
  color: #babdbf !important;
  border-color: #39374e !important;
}

.nav-link-gdc-selected {
  font-weight: bold !important;
}

div.font-montserrat {
  font-family: "Montserrat", sans-serif;
}
div.font-roboto {
  font-family: "Robot", sans-serif;
}
div.font-opensans {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
div.rtl {
  direction: rtl !important;
  text-align: right;
}
.sticky-top {
  width: calc(100% - 280px);
  position: fixed;
}
.marginTop {
  margin-top: 7.2% !important;
}

/* text color */
.text-success {
  color: #4caf6b !important;
}
.text-danger {
  color: #eb1e1e !important;
}
.text-info {
  color: #417dd0 !important;
}
/* end of text color */

/* btn color */
.btn-danger {
  background-color: #eb1e1e !important;
}
/* end of btn color */

/* side menu */
#left-sidebar {
  z-index: 1;
}
/* end of side menu */

/* input form */
.input-group-container {
  position: relative;
}

.input-group-addon {
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  cursor: pointer;
}

.input-group-addon i::before {
  font-size: 1rem !important;
}

.label-side-text {
  cursor: pointer;
  user-select: none;
}

.label-side-text:hover {
  text-decoration: underline;
}

.label-side-text:active {
  filter: brightness(0.85);
}

.upload-input-file {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.upload-input-file:disabled {
  opacity: 0;
}

.input-suggestion-container {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
}

.input-suggestion {
  display: flex;
  flex-direction: row;
  padding: 0.375rem 0.75rem;
  border-bottom: 1px solid #e8e9e9;
  cursor: pointer;
}

.input-suggestion:hover {
  background-color: #e8e9e9;
}

.input-suggestion:last-child {
  border-bottom: none;
}

.form-control.error {
  border-color: #dc3545;
}

.form-control.error:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.input-invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
/* end of input form */

/* pagination button */
.page-link:disabled {
  opacity: 0.65;
  filter: brightness(1.1);
  user-select: none;
  pointer-events: none;
}
/* end of pagination button */

/* dual range slider */
.multi-range-slider {
  border: none;
  box-shadow: none;
  border-radius: none;
}

.multi-range-slider .bar-inner {
  box-shadow: none;
  background-color: #f68d2e;
  border: none;
}

.multi-range-slider .bar-left,
.multi-range-slider .bar-right {
  border: none;
  box-shadow: none;
  background-color: #e8e9e9;
}

.multi-range-slider .thumb::before {
  background-color: white;
  box-shadow: none;
  border-color: grey;
}

.multi-range-slider .caption {
  display: flex;
  bottom: 25px;
  left: 0;
  right: 0;
}

.multi-range-slider .thumb .caption * {
  background-color: transparent;
  box-shadow: none;
  color: black;
  font-weight: bold;
}
/* end of dual range slider */

/* cards */
.collapsed-card-container {
  user-select: none;
  cursor: pointer;
}
/* end of cards */

/* react modal */
.ReactModal__Content {
  display: flex;
  flex-direction: column;
  border: none !important;
  background-color: none !important;
  padding: 0 !important;
  min-width: 300px;
  max-width: 1200px !important;
  max-height: 90% !important;
  top: 10% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -10%) !important;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 9999 !important;
}

#faq-modal {
  width: 800px;
}
/* end of react modal */

/* alert modal */
.swal2-container {
  z-index: 9999;
}
/* end of alert modal */

/* date picker */
.react-datepicker-popper {
  z-index: 5;
}
/* end of date picker */

/* other */
.cursor-pointer {
  cursor: pointer;
}

.hover:hover {
  filter: brightness(0.85);
}
.hover:active {
  filter: brightness(0.8);
}
/* end of other */

/* text editor */
.faq-text-editor-wrapper {
  color: #495057;
  border: 1px solid #e8e9e9 !important;
  border-radius: 0.25rem;
  overflow: hidden;
}

.faq-text-editor-toolbar {
  border-top: none;
  border-left: none;
  border-right: none;
}

.faq-text-editor {
  padding: 0 0.75rem !important;
}
/* end of text editor */

/* faq form */
.event-faq-container p {
  display: inline;
}
/* end of faq form */

/* chat card */
.chat-card-container {
  background: #ffff;
  cursor: pointer;
}

.chat-card-container.selected,
.chat-card-container:hover {
  background: #f2f2f2;
}

.chat-card-container:active {
  background: #e5e5e5;
}

/* end of chat card */

/* user card */
.user-card-container {
  background: #ffff;
}

.user-card-container.selected,
.user-card-container:hover {
  background: #f2f2f2;
}

/* end of user card */

/* notification card */

.notification-card {
  user-select: none;
  cursor: pointer;
  background: white;
}

.notification-card:hover {
  background: #f2f2f2;
}

.notification-card:active {
  background: #e5e5e5;
}

/* end of notification card */

/* select input option */

.optionContainer .option:hover {
  background-color: #f68d2e;
}

/* end of select input option */

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .ReactModal__Content {
    max-width: 90% !important;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Large devices (desktops, more than 1200px and up) */
@media (min-width: 1200px) {
}
